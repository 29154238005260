import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface InfoContentProps {
  $margin: 'top' | 'bottom' | 'both' | 'none';
}

export const InfoContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$margin',
})<InfoContentProps>(({ theme, $margin }) => ({
  maxWidth: 855,
  marginTop: ['both', 'top'].includes($margin) ? theme.spacing(15) : 0,
  marginBottom: ['both', 'bottom'].includes($margin) ? theme.spacing(15) : 0,

  [theme.breakpoints.down('md')]: {
    paddingBlock: theme.spacing(6),
  },
}));
