import { Box, Container, Typography } from '@mui/material';
import React from 'react';

import config from 'config/env';

import { StaffStrapi } from 'services/api/types';
import { theme } from 'theme';

import CustomImage from '../CustomImage';
import {
  Description,
  DescriptionServiceCard,
  Section,
  SectionTitle,
  TeamSectionCard,
  TeamSectionCardRight,
  TeamSectionCards,
  Title,
} from './index';
import useMedia from 'hooks/useMedia';

interface Props {
  title: string;
  description?: string;
  margin?: 'top' | 'bottom' | 'both' | 'none';
  headerSize?: 'small' | 'large';
  isHeroSection?: boolean;
  teams?: StaffStrapi[];
}

const TeamSection = ({
  title,
  description,
  margin = 'both',
  headerSize = 'small',
  isHeroSection = false,
  teams,
}: Props) => {
  const { isSmall } = useMedia();
  return (
    <Container sx={{ background: theme.palette.global01['100'], minWidth: '100%' }}>
      <Container>
        <Section component="section" $margin={margin}>
          <SectionTitle
            gutterBottom
            variant={headerSize === 'large' ? 'heading01' : 'heading02'}
            {...(isHeroSection && { component: 'h1' })}
          >
            {title}
          </SectionTitle>
          {description && <Description variant="paragraph01">{description}</Description>}
          {teams && (
            <TeamSectionCards $columns={2}>
              {teams &&
                Array.isArray(teams) &&
                teams.map((team, i) => (
                  <TeamSectionCard key={i}>
                    <Box
                      sx={{
                        width: '105px',
                        height: '105px',
                        maxWidth: { lg: '100px', md: '163px', sm: '163px', xs: '100px' },
                      }}
                    >
                      <CustomImage
                        src={`${config.API_BASE}${team.img.data.attributes.url}?updatedAt=${team.img.data.attributes.updatedAt}`}
                        alt={team.imgAlt || ''}
                        width={'105px'}
                        height={'105px'}
                        style={{
                          borderRadius: theme.spacing(1),
                        }}
                      />
                    </Box>

                    <TeamSectionCardRight>
                      <Title>
                        <Typography
                          fontWeight={700}
                          variant={isSmall ? 'paragraph02' : 'heading05'}
                        >
                          {team.fullName}
                        </Typography>
                      </Title>
                      <DescriptionServiceCard
                        style={{
                          width: '8rem',
                        }}
                      >
                        <Typography variant={isSmall ? 'caption01' : 'paragraph02'}>
                          {' '}
                          {team.onPosition}
                        </Typography>
                      </DescriptionServiceCard>
                    </TeamSectionCardRight>
                  </TeamSectionCard>
                ))}
            </TeamSectionCards>
          )}
          {/* <SectionLeft
          {...(!isMedium
            ? {
                order: inverted ? 1 : 0,
              }
            : {
                order: invertedMobile ? 1 : 0,
              })}
          $fullwidth={!img}
        ></SectionLeft>

        <SectionRight
          {...(!isMedium
            ? {
                order: inverted ? 0 : 1,
              }
            : {
                order: invertedMobile ? 0 : 1,
              })}
          sx={{
            justifyContent: !inverted ? 'flex-end' : 'flex-start',
            ...(isMedium && { justifyContent: 'center' }),
            ...(isSmall && { justifyContent: 'center' }),
          }}
        >
          {services && (
            <TeamSectionCards $columns={1}>
              {services &&
                services.map((service, i) => (
                  <TeamSectionCard key={i}>
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        maxWidth: '155px',
                      }}
                    >
                      {IconLookup[service]}
                    </Box>

                    <TeamSectionCardRight>
                      <Title>
                        <Typography fontWeight={700} variant="heading04">
                          <FormattedMessage id={`home.service.${service}` as I18nKey} />
                        </Typography>
                      </Title>
                      <DescriptionServiceCard variant="paragraph01">
                        <FormattedMessage id={`home.service.${service}.description` as I18nKey} />
                      </DescriptionServiceCard>
                      <Link
                        href={`/${service}`}
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      >
                        <Typography fontWeight={700} variant="paragraph02">
                          <FormattedMessage id={`home.service.cta` as I18nKey} />
                        </Typography>
                        <IcoArrowRight
                          style={{ width: '9.5px', height: '9px', marginLeft: '7px' }}
                        />
                      </Link>{' '}
                    </TeamSectionCardRight>
                  </TeamSectionCard>
                ))}
            </TeamSectionCards>
          )}
        </SectionRight> */}
        </Section>
      </Container>
    </Container>
  );
};

export default TeamSection;
