import { useEffect, useState } from 'react';

const useSearchDebounce = (searchTerm: string, delay: number): string[] => {
  const [searchResults, setSearchResults] = useState<string[]>([]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      // Split the search term into an array of words
      const words = searchTerm.trim().split(' ');
      setSearchResults(words);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm, delay]);

  return searchResults;
};

export default useSearchDebounce;
