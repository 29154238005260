import { Button, Container } from '@mui/material';

import useMedia from 'hooks/useMedia';
import { theme } from 'theme';

import { globalModal } from 'components/@common/GlobalForm/GlobalForm';

import { ButtonsContainer, Description, GetStartedContainer, Title } from '.';

interface Props {
  title?: string;
  description?: string;
  labelButton?: string;
}
const GetStarted = ({ title, description, labelButton }: Props) => {
  const { isSmall } = useMedia();

  return (
    <Container
      sx={{
        marginBottom: {
          lg: theme.spacing(15),
          sm: theme.spacing(5),
          md: theme.spacing(15),
          xs: theme.spacing(5),
        },
      }}
    >
      <GetStartedContainer>
        <Title variant="heading03">{title}</Title>

        <Description fontWeight="700" variant="heading05">
          {description}
        </Description>

        <ButtonsContainer>
          <Button
            size="large"
            fullWidth={!isSmall ? false : true}
            variant="contained"
            color="secondary"
            onClick={() => globalModal.show()}
          >
            {labelButton}
          </Button>
        </ButtonsContainer>
      </GetStartedContainer>
    </Container>
  );
};

export default GetStarted;
