import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { QuestionStrapi } from 'services/api/types';
import { theme } from 'theme';

import { IcoMinus, IcoPlus } from 'components/@icons';

import { QuestionsLeft, QuestionsRight, QuestionsSection } from '.';

interface Props {
  title?: string;
  questions?: QuestionStrapi[];
}

const Questions = ({ title, questions }: Props) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container sx={{ background: theme.palette.global01['700'], minWidth: '100%', padding: 0 }}>
      <Container>
        <QuestionsSection>
          <QuestionsLeft>
            <Typography variant="heading02">{title}</Typography>
          </QuestionsLeft>
          <QuestionsRight>
            {questions &&
              questions.map(({ question, answer }, index) => (
                <Accordion
                  expanded={expanded === `panel${index + 1}`}
                  onChange={handleChange(`panel${index + 1}`)}
                  aria-controls={`panel${index + 1}bh-content`}
                  aria-expanded={expanded === `panel${index + 1}`}
                  aria-label="Expand question"
                  id={`panel${index + 1}bh-header`}
                  key={index}
                  sx={{ background: theme.palette.global01['700'] }}
                >
                  <AccordionSummary
                    expandIcon={expanded === `panel${index + 1}` ? <IcoMinus /> : <IcoPlus />}
                  >
                    <Typography variant="heading05">
                      {/* <FormattedMessage id={question} /> */}
                      {question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="paragraph01"
                      sx={(theme) => ({ color: theme.palette.neutral01[300] })}
                    >
                      {/* <FormattedMessage id={answer} /> */}
                      {answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </QuestionsRight>
        </QuestionsSection>
      </Container>
    </Container>
  );
};

export default Questions;
