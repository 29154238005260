import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { parseISO } from 'date-fns';
import React from 'react';

import { theme } from 'theme';

interface ConvertDatetoImageProps {
  date: string;
  isHeroSection?: boolean;
}

const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    backgroundColor: theme.palette.global01[700],
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(12),
    color: theme.palette.global01[400],
    fontWeight: 'bold',
    padding: 8,
  },
});

const ConvertDatetoImage = ({ date, isHeroSection = false }: ConvertDatetoImageProps) => {
  const classes = useStyles();

  function getMonth(dateString: string): string {
    const date = parseISO(dateString);
    const month = date.getMonth() + 1;
    return month.toString().padStart(2, '0');
  }

  return (
    <Card
      className={classes.card}
      sx={{ width: isHeroSection ? '365px' : '48px', height: isHeroSection ? '361px' : '48px' }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: isHeroSection ? '8.625rem' : '18px',
            fontWeight: isHeroSection ? '900' : '700',
          }}
          component={'div'}
        >
          {parseISO(date).getDate().toString().padStart(2, '0')}
        </Typography>
        <Typography
          sx={{
            fontSize: isHeroSection ? '8.625rem' : '18px',
            fontWeight: isHeroSection ? '900' : '700',
            marginTop: isHeroSection ? '-50px' : '0px',
          }}
          component={'div'}
        >
          {getMonth(date)}
        </Typography>
      </Box>
    </Card>
  );
};

export default ConvertDatetoImage;
