import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Box, Typography } from '@mui/material';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import config from 'config/env';

import { ImageStrapi } from 'services/api/types';
import { theme } from 'theme';

import CustomImage from '../CustomImage/CustomImage';
import { CarouselContainer } from './Carousel.style';

interface CarouselProps {
  title?: string;
  images?: ImageStrapi[];
  logo?: ImageStrapi;
}

const Carousel = ({ images, title, logo }: CarouselProps) => {
  const swiperOption = {
    slidesPerView: 1,
    loop: true,
    speed: 1200,
    autoplay: {
      delay: 4000,
    },
    navigation: {
      nextEl: '.digi-nav-control .swiper-button-next',
      prevEl: '.digi-nav-control .swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      zIndex: 99999,
    },
  };

  return (
    <CarouselContainer>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 999,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgba(0,12,97,0.3)',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: { lg: '50%', md: '40%', sm: '40%', xs: '40%' },
            maxHeight: '40%',
          }}
        >
          {logo && Array.isArray(logo?.img?.data) && (
            <CustomImage
              src={`${config.API_BASE}${logo.img.data[0].attributes.url}?updatedAt=${logo.img.data[0].attributes.updatedAt}`}
              alt={logo.imgAlt || ''}
              width={logo.img.data[0].attributes.height}
              height={logo.img.data[0].attributes.height}
            />
          )}
        </Box>
        <Typography
          fontWeight={700}
          color={theme.palette.neutral01[700]}
          variant="heading01"
          component={'h1'}
        >
          {title && title}
        </Typography>
      </Box>
      <Swiper
        effect="coverflow"
        {...swiperOption}
        modules={[Navigation, Autoplay, Pagination, EffectFade]}
        pagination
        style={{ height: '100%', width: '100%' }}
      >
        {images &&
          Array.isArray(images) &&
          images.map((image) => (
            <SwiperSlide
              key={image.id}
              title={image.imgAlt}
              style={{ maxHeight: '76vh', width: '100%' }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  minHeight: { lg: '100%', md: '66vh', sm: '66vh', xs: '66vh' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 0,
                  margin: 'auto',
                  objectFit: 'cover',
                  '& > span': {
                    position: 'initial !important',
                  },
                }}
              >
                <CustomImage
                  src={
                    `${config.API_BASE}${image.img.data[0].attributes.url}?updatedAt=${image.img.data[0].attributes.updatedAt}` ||
                    ''
                  }
                  alt={image.imgAlt || ''}
                  width={image.img.data[0].attributes.width}
                  height={image.img.data[0].attributes.height}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                  objectFit="cover"
                />
              </Box>
            </SwiperSlide>
          ))}
      </Swiper>
    </CarouselContainer>
  );
};

export default Carousel;
