import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { IcoCheckBold } from 'components/@icons';

export const DirectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBlock: theme.spacing(15),

  [theme.breakpoints.down('md')]: {
    marginBlock: theme.spacing(6),
  },
}));

interface Props {
  $columns: number;
}

export const DirectCards = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$columns',
})<Props>(({ theme, $columns }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: `repeat(${$columns}, 1fr)`,
  gap: theme.spacing(3),
  marginTop: theme.spacing(6),

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));
export const DirectCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.neutral01[600]}`,
  padding: theme.spacing(3, 3),

  [theme.breakpoints.down('md')]: {
    margin: '0 auto',
    width: '100%',
  },
  '&:hover': {
    cursor: 'pointer',
  },
}));
export const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const Description = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(3),
  },
}));

export const Divider = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '1px',
  backgroundColor: theme.palette.neutral01[600],
  marginBlock: theme.spacing(3),
}));

export const Features = styled(Box)(({ theme }) => ({
  marginBlock: theme.spacing(2, 3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
export const Feature = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
}));

export const IcoCheckBoldGreen = styled(IcoCheckBold)(({ theme }) => ({
  color: theme.palette.success.main,
  minWidth: '24px',
  minHeight: '24px',
}));

export const HiddenOnMobile = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
