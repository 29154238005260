import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface SectionProps {
  $margin: 'top' | 'bottom' | 'both' | 'none';
}
interface Props {
  $columns: number;
}

export const Section = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$margin',
})<SectionProps>(({ theme, $margin }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginTop: ['both', 'top'].includes($margin) ? theme.spacing(15) : 0,
  marginBottom: ['both', 'bottom'].includes($margin) ? theme.spacing(15) : 0,

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginTop: ['both', 'top'].includes($margin) ? theme.spacing(6) : 0,
    marginBottom: ['both', 'bottom'].includes($margin) ? theme.spacing(6) : 0,
    gap: theme.spacing(4),
  },
}));

export const SectionLeft = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$fullwidth',
})<{ $fullwidth: boolean }>(({ theme, $fullwidth }) => ({
  width: $fullwidth ? '100%' : '50%',
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const SectionRight = styled(Box)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral01[200],
  margin: 0,
}));

export const Description = styled(Typography)(({ theme }) => ({
  maxWidth: '666px',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    maxWidth: '343px',
  },
}));

export const VacanciesSectionCards = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$columns',
})<Props>(({ theme, $columns }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: `repeat(${$columns}, 1fr)`,
  gap: theme.spacing(3),
  marginTop: theme.spacing(6),

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));
export const VacanciesSectionCard = styled(Box)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(1),
  gap: theme.spacing(1),
  border: `1px solid ${theme.palette.neutral01[600]}`,
  padding: theme.spacing(3, 2),

  [theme.breakpoints.down('md')]: {
    margin: '0 auto',
    width: '100%',
  },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
    margin: '0 auto',
    width: '100%',
  },
}));

export const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const MoreDetailButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: theme.palette.primary.main,
  fill: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.global01[300],
    fill: theme.palette.global01[300],
    gap: theme.spacing(0.5),
  },
}));
