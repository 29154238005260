import { Box, Button, Container, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useIntl } from 'react-intl';

import { languageCodeMap } from 'utils/localConvert';
import { regexEmail } from 'utils/regexUtils';

import { useLocale } from 'hooks/useLocale';
import { EmailRegisteredService } from 'services/api';
import { EmailRegisteredStrapiType } from 'services/api/types';
import { theme } from 'theme';

import { TextField } from 'components/@common';
import { IcoMail } from 'components/@icons';

import { Title } from '../DefaultSection';
import { SupportContainer, SupportSectionLeft, SupportSectionRight } from './Support.style';

interface Props {
  title?: string;
  labelButton?: string;
}
const Support = ({ title, labelButton }: Props) => {
  const { locale } = useLocale();
  const intl = useIntl();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EmailRegisteredStrapiType>();

  const onSubmitForm = handleSubmit((data) => {
    const dataForSubmit = { ...data, locale: languageCodeMap[locale] };
    toast.loading(intl.formatMessage({ id: 'common.notification.loading' }));
    handleSubmitForm(dataForSubmit);
  });

  const handleSubmitForm = async (data: EmailRegisteredStrapiType) => {
    try {
      const response = await EmailRegisteredService.createNewEntry(data);
      if (response && response?.data) {
        toast.dismiss();
        toast.success(intl.formatMessage({ id: 'common.notification.success' }));
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.error(intl.formatMessage({ id: 'common.notification.error' }));
    }
  };

  return (
    <Container
      sx={{
        background: theme.palette.global01['100'],
        minWidth: '100%',
        borderTop: '1px solid ',
        borderColor: theme.palette.global01[300],
        padding: 0,
      }}
    >
      <SupportContainer>
        <SupportSectionLeft>
          <Box
            sx={{
              borderRadius: 100,
              minWidth: theme.spacing(6),
              minHeight: theme.spacing(6),
              backgroundColor: theme.palette.global01['300'],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: theme.spacing(2),
            }}
          >
            <IcoMail width="36px" height="32px" fill={theme.palette.background.paper} />
          </Box>
          <Title>
            <Typography variant="heading05" color={theme.palette.background.paper}>
              {title}
            </Typography>
          </Title>
        </SupportSectionLeft>
        <SupportSectionRight>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: regexEmail,
                message: 'Error',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ''}
                flex={1}
                hasError={errors.email ? true : false}
                errorMessage={
                  errors?.email?.type == 'required'
                    ? 'contactForm.error.required'
                    : 'contactForm.error.pattern'
                }
                placeholder={intl.formatMessage({ id: 'common.email.placeholder' })}
                handleChange={(value) => field.onChange(value)}
              />
            )}
          />
          <Link href={`support.description` as I18nKey}>
            <Button
              size="medium"
              sx={{
                width: '100%',
              }}
              variant="contained"
              color="primary"
              onClick={onSubmitForm}
            >
              {labelButton}
            </Button>
          </Link>
        </SupportSectionRight>
      </SupportContainer>
    </Container>
  );
};

export default Support;
