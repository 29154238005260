import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CarouselContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  position: 'relative',
  // [theme.breakpoints.down('md')]: {
  //   height: '66vh',
  // },
}));
