import { makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import React from 'react';

import { theme } from 'theme';

import { Description, Title } from './SeacrchEventSection.style';

interface HighlightedTextProps {
  originalText: string;
  searchTerm: string[];
  type: 'title' | 'desc';
}

const useStyles = makeStyles(() => ({
  highlighted: {
    backgroundColor: theme.palette.global01[700],
    color: theme.palette.global01[400],
  },
}));

const HighlightedText: React.FC<HighlightedTextProps> = ({
  originalText,
  searchTerm,
  type = 'desc',
}) => {
  const classes = useStyles();

  const getHighlightedText = () => {
    if (!searchTerm.length || searchTerm.some((term) => term.trim() === '')) {
      return originalText;
    }

    const regex = new RegExp(`(${searchTerm.join('|')})`, 'gi');
    const parts = originalText.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? (
        <mark key={index} className={classes.highlighted}>
          {part}
        </mark>
      ) : (
        <React.Fragment key={index}>{part}</React.Fragment>
      ),
    );
  };

  return (
    <>
      {type === 'title' && (
        <Title>
          <Typography fontWeight={700} variant="heading05">
            {getHighlightedText()}
          </Typography>
        </Title>
      )}
      {type === 'desc' && (
        <Title>
          <Description variant="paragraph02">{getHighlightedText()}</Description>
        </Title>
      )}
    </>
  );
};

export default HighlightedText;
