import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const QuestionsSection = styled(Box)(({ theme }) => ({
  paddingBlock: theme.spacing(15),
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(3),
  background: theme.palette.global01['700'],

  [theme.breakpoints.down('md')]: {
    padding: 0,
    gridTemplateColumns: '1fr',
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down('lg')]: {
    padding: 0,
    gridTemplateColumns: '1fr',
    paddingBlock: theme.spacing(6),
    gap: theme.spacing(2),
  },
}));

export const QuestionsLeft = styled(Box)(({ theme }) => ({
  background: theme.palette.global01['700'],
}));

export const QuestionsRight = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(-2),
  background: theme.palette.global01['700'],
}));
