import { Box, Container, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { languageCodeMap } from 'utils/localConvert';

import { useLocale } from 'hooks/useLocale';
import { DirectsService } from 'services/api';
import { DirectStrapi } from 'services/api/types';
import { theme } from 'theme';

import { IcoShare } from 'components/@icons';

import { Description, DirectCard, DirectCards, DirectContainer, Title } from './Direct.style';

interface Props {
  title?: string;
  description?: string;
}

const Direct = ({ title, description }: Props) => {
  const router = useRouter();
  const { locale } = useLocale();
  const [directs, setDirects] = useState<DirectStrapi[] | []>([]);
  useEffect(() => {
    if (locale) {
      loadData(languageCodeMap[locale]);
    }
  }, [locale]);

  const loadData = async (locale: string) => {
    try {
      const response = await DirectsService.getAllDirects(`locale=${locale}`);
      if (response && response?.data) {
        setDirects(response?.data);
      } else {
        setDirects([]);
      }
    } catch (error) {
      Sentry.captureException(error);
      // Even if the API failed, we still go to the next step, but hide description in the Organisation step
    } finally {
    }
  };
  return (
    <Container>
      <DirectContainer>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          })}
        >
          {title && (
            <Typography sx={{ textAlign: { sm: 'left', md: 'center' } }} variant="heading02">
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                textAlign: { sm: 'left', md: 'center' },
              })}
              variant="paragraph01"
            >
              {description}
            </Typography>
          )}
        </Box>
        {directs && Array.isArray(directs) && (
          <DirectCards $columns={directs.length}>
            {directs &&
              directs.map((direct, i) => (
                <DirectCard key={i}>
                  <Title>
                    <Typography fontWeight={700} variant="heading04">
                      {direct.attributes.title}
                    </Typography>
                  </Title>
                  <Description variant="paragraph01">{direct.attributes.description}</Description>
                  <Link href={`${direct.attributes.url}`} rel="noopener noreferrer" target="_blank">
                    <Box
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        display: 'flex',
                        color: theme.palette.primary.main,
                        fill: theme.palette.primary.main,
                        marginTop: theme.spacing(2),
                        '&:hover': {
                          color: theme.palette.global01[300],
                          fill: theme.palette.global01[300],
                          gap: theme.spacing(0.5),
                        },
                      }}
                    >
                      <Typography fontWeight={700} variant="paragraph02">
                        {direct.attributes.labelButton}
                      </Typography>
                      <IcoShare style={{ width: '25px', height: '25px' }} />
                    </Box>
                  </Link>
                </DirectCard>
              ))}
          </DirectCards>
        )}
      </DirectContainer>
    </Container>
  );
};

export default Direct;
