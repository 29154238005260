import { Box, Container, Typography } from '@mui/material';
import React from 'react';

import config from 'config/env';

import OtherSay from '/public/images/orther_say.png';

import useMedia from 'hooks/useMedia';
import { ImageStrapi, UserSayStrapi } from 'services/api/types';
import { theme } from 'theme';

import CustomImage from '../CustomImage/CustomImage';
import {
  Description,
  OtherSaySectionCard,
  OtherSaySectionCardRight,
  OtherSaySectionCards,
  Section,
  SectionContent,
  SectionLeft,
  SectionRight,
  SectionTitle,
} from './OtherSaySection.style';

interface Props {
  title: string;
  image: ImageStrapi;
  margin?: 'top' | 'bottom' | 'both' | 'none';
  headerSize?: 'small' | 'large';
  titleMaxWidth?: string;
  isHeroSection?: boolean;
  inverted?: boolean;
  invertedMobile?: boolean;
  otherSays?: UserSayStrapi[];
}

const OtherSaySection = ({
  title,
  image,
  margin = 'both',
  headerSize = 'small',
  titleMaxWidth = 'none',
  isHeroSection = false,
  inverted = false,
  invertedMobile = false,
  otherSays,
}: Props) => {
  const { isSmall, isMedium } = useMedia();

  return (
    <Container
      sx={{
        background: theme.palette.global01['700'],
        minWidth: '100%',
      }}
    >
      <Section component="section" $margin={margin}>
        <SectionTitle
          gutterBottom
          variant={headerSize === 'large' ? 'heading01' : 'heading02'}
          {...(isHeroSection && { component: 'h1' })}
          maxWidth={titleMaxWidth}
        >
          {title}
        </SectionTitle>
        <SectionContent $margin={'none'}>
          <SectionLeft
            {...(!isMedium
              ? {
                  order: inverted ? 1 : 0,
                }
              : {
                  order: invertedMobile ? 1 : 0,
                })}
            $fullwidth={false}
          >
            <>
              {otherSays && (
                <OtherSaySectionCards $columns={1}>
                  {otherSays &&
                    otherSays.map((otherSay, i) => (
                      <OtherSaySectionCard key={i}>
                        <Box
                          sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            minWidth: '100px',
                          }}
                        >
                          <CustomImage
                            src={`${config.API_BASE}${otherSay.img.data.attributes.url}?updatedAt=${image.img.data[0].attributes.updatedAt}`}
                            alt={otherSay.imgAlt || ''}
                            width={otherSay.img.data.attributes.width}
                            height={otherSay.img.data.attributes.height}
                            style={{ borderRadius: 10 }}
                          />
                        </Box>
                        <OtherSaySectionCardRight>
                          <Typography
                            fontWeight={400}
                            sx={{ color: theme.palette.neutral01[100] }}
                            variant="paragraph01"
                          >
                            {`" ${otherSay.message} "`}
                          </Typography>
                          <Description
                            variant="paragraph01"
                            sx={{ color: theme.palette.neutral01[300] }}
                          >
                            {`${otherSay.fullName} - ${otherSay.onPosition}`}
                          </Description>
                        </OtherSaySectionCardRight>
                      </OtherSaySectionCard>
                    ))}
                </OtherSaySectionCards>
              )}
            </>
          </SectionLeft>
          {image && (
            <SectionRight
              {...(!isMedium
                ? {
                    order: inverted ? 0 : 1,
                  }
                : {
                    order: invertedMobile ? 0 : 1,
                  })}
              sx={{
                justifyContent: inverted ? 'flex-end' : 'flex-start',
                ...(isMedium && { justifyContent: 'center' }),
                ...(isSmall && { justifyContent: 'center' }),
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  maxWidth: isHeroSection ? '564px' : '465px',
                }}
              >
                <CustomImage
                  src={`${config.API_BASE}${image.img.data[0].attributes.url}?updatedAt=${image.img.data[0].attributes.updatedAt}`}
                  alt={image.imgAlt || ''}
                  width={image.img.data[0].attributes.width}
                  height={image.img.data[0].attributes.height}
                />
              </Box>
            </SectionRight>
          )}
        </SectionContent>
      </Section>
    </Container>
  );
};

export default OtherSaySection;
