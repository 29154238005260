import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GetStartedContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.global01[400],
  borderRadius: theme.spacing(4),
  padding: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 4),
    gap: theme.spacing(2),
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral01[700],
}));

export const Description = styled(Typography)(({ theme }) => ({
  maxWidth: 540,
  color: theme.palette.neutral01[700],

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(2),
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));
