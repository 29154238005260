import { Box, Button, Container, Stack, styled, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import config from 'config/env';

import ConvertDatetoImage from 'utils/convertDatetoImage';
import { languageCodeMap } from 'utils/localConvert';

import { useLocale } from 'hooks/useLocale';
import useMedia from 'hooks/useMedia';
import { EventsService } from 'services/api';
import { ButtonStrapi, EventStrapi, ImageStrapi } from 'services/api/types';

import { IcoArrowRight, IcoCheckBold, IcoShare } from 'components/@icons';

import CustomImage from '../CustomImage';
import {
  DefaultSectionCard,
  DefaultSectionCardRight,
  DefaultSectionCards,
  Description,
  MoreDetailButton,
  Section,
  SectionLeft,
  SectionRight,
  SectionTitle,
  Title,
} from '.';

interface Props {
  icon?: React.ReactElement;
  tagline?: I18nKey;
  title?: string;
  description?: string;
  checks?: { id: number; title: string }[];
  primaryButton?: ButtonStrapi;
  secondaryButton?: ButtonStrapi;
  thirdButton?: ButtonStrapi;
  forthButton?: ButtonStrapi;
  openNewTabButton?: ButtonStrapi;
  image?: ImageStrapi;
  margin?: 'top' | 'bottom' | 'both' | 'none';
  headerSize?: 'small' | 'large';
  imgMaxWidth?: string;
  titleMaxWidth?: string;
  isHeroSection?: boolean;
  inverted?: boolean;
  invertedMobile?: boolean;
  getEvents?: boolean;
  dateForEvent?: string;
}

const DefaultSection = ({
  icon,
  tagline,
  title,
  description,
  checks,
  primaryButton,
  secondaryButton,
  thirdButton,
  forthButton,
  openNewTabButton,
  image,
  margin = 'both',
  headerSize = 'small',
  imgMaxWidth,
  titleMaxWidth = 'none',
  isHeroSection = false,
  inverted = false,
  invertedMobile = false,
  getEvents = false,
  dateForEvent,
}: Props) => {
  const { isSmall, isMedium } = useMedia();
  const router = useRouter();
  const { locale } = useLocale();
  const { eventSlug } = router.query;
  const IcoCheckBoldGreen = styled(IcoCheckBold)(({ theme }) => ({
    color: theme.palette.success.main,
    minWidth: '24px',
    minHeight: '24px',
  }));
  const [events, setEvents] = useState<EventStrapi[] | []>([]);

  useEffect(() => {
    if (locale && getEvents) {
      loadData(languageCodeMap[locale]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, getEvents, eventSlug]);

  const loadData = async (locale: string) => {
    try {
      let getEvent;
      if (eventSlug) {
        getEvent = {
          sort: {
            date: 'ASC',
          },
          locale: locale,
          filters: {
            url: {
              $ne: `${eventSlug}`,
            },
          },
        };
      } else {
        getEvent = {
          sort: {
            date: 'ASC',
          },
          locale: locale,
        };
      }
      const response = await EventsService.getAllEvents(
        QueryString.stringify(getEvent, {
          encodeValuesOnly: true, // prettify URL
        }),
      );
      if (response && response?.data) {
        if (Array.isArray(response?.data) && response?.data.length > 3) {
          setEvents(response?.data.splice(0, 3));
        } else {
          setEvents(response?.data);
        }
      } else {
        setEvents([]);
      }
    } catch (error) {
      Sentry.captureException(error);
      // Even if the API failed, we still go to the next step, but hide description in the Organisation step
    } finally {
    }
  };

  return (
    <Container>
      <Section component="section" $margin={margin}>
        <SectionLeft
          {...(!isMedium
            ? {
                order: inverted ? 1 : 0,
              }
            : {
                order: invertedMobile ? 1 : 0,
              })}
          $fullwidth={!image}
        >
          <>
            {tagline && (
              <Stack
                direction="row"
                gap={1}
                sx={(theme) => ({
                  color: theme.palette.neutral01[400],
                  fill: theme.palette.neutral01[400],
                })}
              >
                {icon && React.cloneElement(icon, { style: { width: '32px', height: '32px' } })}
                <Typography variant="heading04">
                  <FormattedMessage id={tagline} />
                </Typography>
              </Stack>
            )}
            <SectionTitle
              gutterBottom
              variant={tagline || headerSize == 'large' ? 'heading01' : 'heading03'}
              {...(isHeroSection && { component: 'h1' })}
              maxWidth={titleMaxWidth}
            >
              {title}
            </SectionTitle>
            {description && <Description variant="paragraph01">{description}</Description>}
            {getEvents && events && (
              <DefaultSectionCards $columns={1}>
                {events &&
                  events.map((event, i) => (
                    <DefaultSectionCard
                      key={i}
                      onClick={() => router.push(`/events/${event.attributes.url}`)}
                    >
                      {event.attributes?.date && (
                        <ConvertDatetoImage date={event.attributes?.date} />
                      )}
                      <DefaultSectionCardRight>
                        <Title>
                          <Typography fontWeight={700} variant="heading05">
                            {event.attributes.title}
                          </Typography>
                        </Title>
                        <Description variant="paragraph02">
                          {event.attributes.description}
                        </Description>
                        <Link href={`/events/${event.attributes.url}`}>
                          <MoreDetailButton>
                            <Typography fontWeight={700} variant="paragraph02">
                              {event.attributes.labelButton}
                            </Typography>
                            <IcoArrowRight
                              width={'9.5px'}
                              height={'9px'}
                              style={{ marginLeft: '7px' }}
                            />
                          </MoreDetailButton>
                        </Link>
                      </DefaultSectionCardRight>
                    </DefaultSectionCard>
                  ))}
              </DefaultSectionCards>
            )}
            {checks && (
              <Stack gap={1}>
                {checks.map((check, index) => (
                  <Stack direction="row" gap={1} key={index}>
                    <IcoCheckBoldGreen />
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                      variant="paragraph02"
                    >
                      {/* <FormattedMessage id={check} /> */}
                      {check.title}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            )}
            {primaryButton && (
              <Link href={primaryButton.url}>
                <Button
                  size="large"
                  fullWidth={isMedium ? true : false}
                  variant="contained"
                  color="primary"
                >
                  {primaryButton.label}
                </Button>
              </Link>
            )}
            {secondaryButton && (
              <Link href={secondaryButton.url}>
                <Button size="large" variant="outlined" fullWidth={isMedium ? true : false}>
                  {secondaryButton.label}
                </Button>
              </Link>
            )}
            {thirdButton && (
              <Link href={thirdButton.url}>
                <Button size="large" variant="outlined" fullWidth={isMedium ? true : false}>
                  {thirdButton.label}
                  <IcoShare width={'24px'} height={'24px'} />
                </Button>
              </Link>
            )}
            {forthButton && (
              <Link href={forthButton.url}>
                <Button size="large" variant="contained" color="primary">
                  {/* <FormattedMessage id={secondaryButton.label} /> */}
                  {forthButton.label}
                </Button>
              </Link>
            )}
            {openNewTabButton && (
              <Link href={openNewTabButton.url} rel="noopener noreferrer" target="_blank">
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  fullWidth={isMedium ? true : false}
                >
                  {/* <FormattedMessage id={secondaryButton.label} /> */}
                  {openNewTabButton.label}
                  <IcoShare width={'24px'} height={'24px'} />
                </Button>
              </Link>
            )}
          </>
        </SectionLeft>
        {(image || dateForEvent) && (
          <SectionRight
            {...(!isMedium
              ? {
                  order: inverted ? 0 : 1,
                }
              : {
                  order: invertedMobile ? 0 : 1,
                })}
            sx={{
              justifyContent: !inverted ? 'center' : 'flex-start',
              ...(isMedium && { justifyContent: 'center' }),
              ...(isSmall && { justifyContent: 'center' }),
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                maxWidth: imgMaxWidth ? imgMaxWidth : isHeroSection ? '564px' : '465px',
              }}
            >
              {dateForEvent && <ConvertDatetoImage date={dateForEvent} isHeroSection />}
              {image && !dateForEvent && (
                <CustomImage
                  src={`${config.API_BASE}${image.img.data[0].attributes.url}?updatedAt=${image.img.data[0].attributes.updatedAt}`}
                  alt={image.imgAlt || ''}
                  width={image.img.data[0].attributes.width}
                  height={image.img.data[0].attributes.height}
                />
              )}
            </Box>
          </SectionRight>
        )}
      </Section>
    </Container>
  );
};

export default DefaultSection;
