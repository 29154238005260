import { Box, Container, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import React, { useEffect, useState } from 'react';

import config from 'config/env';

import { languageCodeMap } from 'utils/localConvert';

import { useLocale } from 'hooks/useLocale';
import useMedia from 'hooks/useMedia';
import { ServicesService } from 'services/api';
import { ServiceStrapi } from 'services/api/types';
import { theme } from 'theme';

import { IcoArrowRight } from 'components/@icons';

import CustomImage from '../CustomImage';
import {
  Description,
  DescriptionServiceCard,
  MoreDetailButton,
  Section,
  SectionLeft,
  SectionRight,
  SectionTitle,
  ServiceSectionCard,
  ServiceSectionCardRight,
  ServiceSectionCards,
  Title,
} from '.';

interface Props {
  icon?: React.ReactElement;
  tagline?: I18nKey;
  title?: string;
  description?: string;
  margin?: 'top' | 'bottom' | 'both' | 'none';
  headerSize?: 'small' | 'large';
  titleMaxWidth?: string;
  isHeroSection?: boolean;
  inverted?: boolean;
  invertedMobile?: boolean;
}

const ServiceSection = ({
  tagline,
  title,
  description,
  margin = 'both',
  headerSize = 'small',
  titleMaxWidth = 'none',
  isHeroSection = false,
  inverted = false,
  invertedMobile = false,
}: Props) => {
  const { isSmall, isMedium } = useMedia();
  const router = useRouter();
  const { locale } = useLocale();
  const { serviceSlug } = router.query;
  const [services, setServices] = useState<ServiceStrapi[] | []>([]);

  useEffect(() => {
    if (locale) {
      loadData(serviceSlug, languageCodeMap[locale]);
    }
  }, [locale, serviceSlug]);

  const loadData = async (serviceSlug: string | string[] | undefined, locale: string) => {
    try {
      let getServiceBySlugQuery;
      if (serviceSlug) {
        getServiceBySlugQuery = {
          populate: 'deep',
          locale: locale,
          filters: {
            url: {
              $ne: `${serviceSlug}`,
            },
          },
        };
      } else {
        getServiceBySlugQuery = {
          populate: 'deep',
          locale: locale,
        };
      }
      const response = await ServicesService.getAllServices(
        QueryString.stringify(getServiceBySlugQuery, {
          encodeValuesOnly: true, // prettify URL
        }),
      );
      if (response && response?.data) {
        setServices(response?.data);
      } else {
        // router.push('/404');
        setServices([]);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  return (
    <Container sx={{ background: theme.palette.global01['100'], minWidth: '100%', padding: 0 }}>
      <Container>
        <Section component="section" $margin={margin}>
          <SectionLeft
            {...(!isMedium
              ? {
                  order: inverted ? 1 : 0,
                }
              : {
                  order: invertedMobile ? 1 : 0,
                })}
            $fullwidth={false}
          >
            <>
              <SectionTitle
                gutterBottom
                variant={tagline || headerSize === 'large' ? 'heading01' : 'heading03'}
                {...(isHeroSection && { component: 'h1' })}
                maxWidth={titleMaxWidth}
              >
                {title}
              </SectionTitle>
              {description && (
                <Description variant="paragraph01" sx={{ color: theme.palette.global01[600] }}>
                  {description}
                </Description>
              )}
            </>
          </SectionLeft>

          <SectionRight
            {...(!isMedium
              ? {
                  order: inverted ? 0 : 1,
                }
              : {
                  order: invertedMobile ? 0 : 1,
                })}
            sx={{
              justifyContent: !inverted ? 'flex-end' : 'flex-start',
              ...(isMedium && { justifyContent: 'center' }),
              ...(isSmall && { justifyContent: 'center' }),
            }}
          >
            {services && (
              <ServiceSectionCards $columns={1}>
                {services &&
                  services.map((service, i) => (
                    <ServiceSectionCard
                      key={i}
                      onClick={() => router.push(`/services/${service.attributes.url}`)}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          height: '100%',
                          maxWidth: '155px',
                        }}
                      >
                        <CustomImage
                          src={`${config.API_BASE}${service.attributes.image.img.data[0].attributes.url}?updatedAt=${service.attributes.image.img.data[0].attributes.updatedAt}`}
                          alt={service.attributes.image.imgAlt || ''}
                          width={service.attributes.image.img.data[0].attributes.width}
                          height={service.attributes.image.img.data[0].attributes.height}
                        />
                      </Box>

                      <ServiceSectionCardRight>
                        <Title>
                          <Typography fontWeight={700} variant="heading04">
                            {service.attributes.title}
                          </Typography>
                        </Title>
                        <DescriptionServiceCard variant="paragraph01">
                          {service.attributes.description}
                        </DescriptionServiceCard>
                        <Link
                          href={`/services/${service.attributes.url}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <MoreDetailButton>
                            <Typography fontWeight={700} variant="paragraph02">
                              {service.attributes.labelButton}
                            </Typography>
                            <IcoArrowRight
                              style={{
                                width: '9.5px',
                                height: '9px',
                                marginLeft: '7px',
                              }}
                            />
                          </MoreDetailButton>
                        </Link>
                      </ServiceSectionCardRight>
                    </ServiceSectionCard>
                  ))}
              </ServiceSectionCards>
            )}
          </SectionRight>
        </Section>
      </Container>
    </Container>
  );
};

export default ServiceSection;
