import { Box, Button, Container, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { languageCodeMap } from 'utils/localConvert';
import { regexEmail, regexNoWhitespace, regexPhoneNumber } from 'utils/regexUtils';

import { useLocale } from 'hooks/useLocale';
import useMedia from 'hooks/useMedia';
import { ContactFormService } from 'services/api';
import { ContactFormStrapiType, ServiceStrapi } from 'services/api/types';
import { theme } from 'theme';

import { TextField } from 'components/@common';
import { IcoArrow, IcoWarningFilled } from 'components/@icons';

import { FormSection, FormSectionLeft } from './ContactFormContainer.style';

interface Props {
  title?: string;
  descriptions?: { description: string }[];
}

const ContactFormContainer = ({ title, descriptions }: Props) => {
  const intl = useIntl();
  const { isMedium } = useMedia();
  const router = useRouter();
  const { locale } = useLocale();
  const { serviceSlug } = router.query;
  const services = useSelector<RootState, ServiceStrapi[]>((state) => state.serviceStore.services);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ContactFormStrapiType>();
  const [isRecaptchaSuccess, setIsRecaptchaSuccess] = useState<boolean>(false);

  const onChangeRecaptcha = () => {
    setIsRecaptchaSuccess(true);
  };

  const onSubmitForm = handleSubmit((data) => {
    if (isRecaptchaSuccess) {
      if (data.service === 'null') {
        delete data.service;
      }
      const dataForSubmit = { ...data, locale: languageCodeMap[locale] };
      toast.loading(intl.formatMessage({ id: 'common.notification.loading' }));
      handleSubmitForm(dataForSubmit);
    }
  });

  const handleSubmitForm = async (data: ContactFormStrapiType) => {
    try {
      const response = await ContactFormService.createNewEntry(data);
      if (response && response?.data) {
        toast.dismiss();
        toast.success(intl.formatMessage({ id: 'common.notification.success' }));
        reset();
      } else {
        toast.error(intl.formatMessage({ id: 'common.notification.error' }));
      }
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'common.notification.error' }));
    }
  };
  return (
    <Container sx={{ paddingBottom: 15, paddingTop: 15 }}>
      {/* <Box sx={{ width: { sm: '100%', md: '50%' } }}> */}

      <FormSection>
        <FormSectionLeft>
          <Typography variant="heading03" component="h3">
            {title}
          </Typography>
          <Box>
            {descriptions &&
              Array.isArray(descriptions) &&
              descriptions.map((description, index) => (
                <Typography
                  key={index}
                  variant="paragraph01"
                  color="neutral01.300"
                  fontWeight="normal"
                  component="p"
                  sx={{ marginTop: 1 }}
                >
                  {description.description}
                </Typography>
              ))}
          </Box>
        </FormSectionLeft>
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            border: `1px solid ${theme.palette.neutral01[600]}`,
            padding: theme.spacing(3),
            borderRadius: theme.spacing(2),
          }}
        >
          <Box>
            <InputLabel>
              <Typography color={theme.palette.global01} fontSize={10} sx={{ marginTop: 1 }}>
                <FormattedMessage id="contactForm.field_0" />
              </Typography>
            </InputLabel>
            <Controller
              name="service"
              control={control}
              defaultValue={
                services.find((service) => service.attributes.url == serviceSlug)?.id || 0
              }
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    ref={null}
                    error={errors.service ? true : false}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={field.onChange}
                    sx={{ width: '100%', position: 'relative' }}
                    IconComponent={() => (
                      <IcoArrow
                        style={{
                          width: '32px',
                          height: '32px',
                          right: '16px',
                          position: 'absolute',
                          pointerEvents: 'none',
                          fill: theme.palette.global01[400],
                        }}
                      />
                    )}
                  >
                    {services &&
                      services.map((service) => (
                        <MenuItem key={service.id} value={service.id}>
                          {service.attributes.title}
                        </MenuItem>
                      ))}
                    <MenuItem key={10000} value={'null'}>
                      <FormattedMessage id="contactForm.other.option" />
                    </MenuItem>
                  </Select>
                  {errors?.service && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginTop: 1,
                      }}
                    >
                      <IcoWarningFilled height={19} width={19} fill={theme.palette.error.dark} />
                      <Typography color="error.dark" fontSize={14} fontWeight={400}>
                        <FormattedMessage id={'contactForm.error.required'} />
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            />
          </Box>

          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: regexNoWhitespace,
                message: intl.formatMessage({ id: 'contactForm.field_2' }),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                hasError={errors.name ? true : false}
                errorMessage={
                  errors?.name?.type == 'required'
                    ? 'contactForm.error.required'
                    : 'contactForm.error.pattern'
                }
                handleChange={(value) => field.onChange(value)}
                label={intl.formatMessage({ id: 'contactForm.field_2' })}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: regexEmail,
                message: intl.formatMessage({ id: 'contactForm.field_3' }),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={intl.formatMessage({ id: 'contactForm.field_3' })}
                type="email"
                hasError={errors.email ? true : false}
                errorMessage={
                  errors?.email?.type == 'required'
                    ? 'contactForm.error.required'
                    : 'contactForm.error.pattern'
                }
                handleChange={(value) => field.onChange(value)}
              />
            )}
          />

          <Controller
            name="organization"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: regexNoWhitespace,
                message: intl.formatMessage({ id: 'contactForm.field_4' }),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                hasError={errors.organization ? true : false}
                errorMessage={
                  errors?.organization?.type == 'required'
                    ? 'contactForm.error.required'
                    : 'contactForm.error.pattern'
                }
                handleChange={(value) => field.onChange(value)}
                label={intl.formatMessage({ id: 'contactForm.field_4' })}
              />
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            rules={{
              required: false,
              pattern: {
                value: regexPhoneNumber,
                message: intl.formatMessage({ id: 'contactForm.field_5' }),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ''}
                hasError={errors.phoneNumber ? true : false}
                errorMessage={
                  errors?.phoneNumber?.type == 'required'
                    ? 'contactForm.error.required'
                    : 'contactForm.error.pattern'
                }
                handleChange={(value) => field.onChange(value)}
                label={intl.formatMessage({ id: 'contactForm.field_5' })}
              />
            )}
          />

          <Controller
            name="ask"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: regexNoWhitespace,
                message: intl.formatMessage({ id: 'contactForm.field_6' }),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                handleChange={(value) => field.onChange(value)}
                hasError={errors.ask ? true : false}
                errorMessage={
                  errors?.ask?.type == 'required'
                    ? 'contactForm.error.required'
                    : 'contactForm.error.pattern'
                }
                label={intl.formatMessage({ id: 'contactForm.field_6' })}
                multiline
              />
            )}
          />

          <ReCAPTCHA
            sitekey={'6Ld7da8oAAAAAHQg8lXtyqMKgg1H4vBhOTOP603c'}
            onChange={onChangeRecaptcha}
          />
          <Button
            variant="contained"
            size="large"
            fullWidth={isMedium ? true : false}
            onClick={onSubmitForm}
          >
            <FormattedMessage id="contactForm.labelButtonSubmit" />
          </Button>
        </Box>
      </FormSection>

      {/* </Box> */}
    </Container>
  );
};

export default ContactFormContainer;
