import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormSection = styled(Box)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(1),
  gap: theme.spacing(3),
  backgroundColor: theme.palette.neutral01[700],
  gridTemplateColumns: `repeat(2, 1fr)`,

  [theme.breakpoints.down('md')]: {
    margin: '0 auto',
    width: '100%',
    gridTemplateColumns: `repeat(1, 1fr)`,
  },
}));

export const FormSectionLeft = styled(Box)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const FormSectionRight = styled(Box)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
