import { Box, Button, Container, Stack, styled, Typography } from '@mui/material';
import { StaticImageData } from 'next/image';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useMedia from 'hooks/useMedia';
import { ButtonStrapi } from 'services/api/types';
import { theme } from 'theme';

import { IcoCheckBold, IcoShare } from 'components/@icons';

import {
  Description,
  Section,
  SectionLeft,
  SectionRight,
  SectionTitle,
  Title,
  VerkoopSectionCard,
  VerkoopSectionCards,
} from './ProvisionSection.style';

interface Props {
  icon?: React.ReactElement;
  tagline?: I18nKey;
  title: string;
  description?: string;
  checks?: I18nKey[];
  primaryButton?: ButtonStrapi;
  secondaryButton?: ButtonStrapi;
  thirdButton?: ButtonStrapi;
  forthButton?: ButtonStrapi;
  openNewTabButton?: ButtonStrapi;
  img?: StaticImageData;
  imgAlt?: string;
  margin?: 'top' | 'bottom' | 'both' | 'none';
  headerSize?: 'small' | 'large';
  titleMaxWidth?: string;
  isHeroSection?: boolean;
  inverted?: boolean;
  invertedMobile?: boolean;
  provision?: { title: string; description: string }[];
}

const Provision = ({
  icon,
  tagline,
  title,
  description,
  checks,
  primaryButton,
  secondaryButton,
  thirdButton,
  forthButton,
  openNewTabButton,
  img,
  margin = 'both',
  headerSize = 'small',
  titleMaxWidth = 'none',
  isHeroSection = false,
  inverted = false,
  invertedMobile = false,
  provision,
}: Props) => {
  const { isSmall, isMedium } = useMedia();

  const IcoCheckBoldGreen = styled(IcoCheckBold)(({ theme }) => ({
    color: theme.palette.success.main,
    minWidth: '24px',
    minHeight: '24px',
  }));

  return (
    <Container>
      <Section component="section" $margin={margin}>
        <SectionLeft
          {...(!isMedium
            ? {
                order: inverted ? 1 : 0,
              }
            : {
                order: invertedMobile ? 1 : 0,
              })}
          $fullwidth={!img}
        >
          <>
            {tagline && (
              <Stack
                direction="row"
                gap={1}
                sx={(theme) => ({
                  color: theme.palette.neutral01[400],
                  fill: theme.palette.neutral01[400],
                })}
              >
                {icon && React.cloneElement(icon, { style: { width: '32px', height: '32px' } })}
                <Typography variant="heading04">
                  <FormattedMessage id={tagline} />
                </Typography>
              </Stack>
            )}

            {provision && (
              <VerkoopSectionCards $columns={1}>
                {provision &&
                  provision.map((provisionItem, i) => (
                    <VerkoopSectionCard key={i}>
                      <Title>
                        <Typography fontWeight={700} variant="heading05">
                          {provisionItem.title}
                        </Typography>
                      </Title>
                      <Description variant="paragraph01">{provisionItem.description}</Description>
                    </VerkoopSectionCard>
                  ))}
              </VerkoopSectionCards>
            )}
            {checks && (
              <Stack gap={1}>
                {checks.map((check, index) => (
                  <Stack direction="row" gap={1} key={index}>
                    <IcoCheckBoldGreen />
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                      variant="paragraph02"
                    >
                      <FormattedMessage id={check} />
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            )}
          </>
        </SectionLeft>
        <SectionRight
          {...(!isMedium
            ? {
                order: inverted ? 0 : 1,
              }
            : {
                order: invertedMobile ? 0 : 1,
              })}
          sx={{
            justifyContent: !inverted ? 'flex-end' : 'flex-start',
            ...(isMedium && { justifyContent: 'center' }),
            ...(isSmall && { justifyContent: 'center' }),
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              alignItems: 'left',
            }}
          >
            <SectionTitle
              gutterBottom
              variant={tagline || headerSize === 'large' ? 'heading01' : 'heading02'}
              {...(isHeroSection && { component: 'h1' })}
              maxWidth={titleMaxWidth}
            >
              {title}
            </SectionTitle>
            {description && <Description variant="paragraph01">{description}</Description>}
            <Box>
              {primaryButton && (
                <Link href={primaryButton.url}>
                  <Button
                    size="large"
                    sx={{ mt: theme.spacing(2) }}
                    fullWidth={isMedium ? true : false}
                  >
                    {primaryButton.label}
                  </Button>
                </Link>
              )}
              {secondaryButton && (
                <Link href={secondaryButton.url}>
                  <Button size="large" variant="outlined" fullWidth={isMedium ? true : false}>
                    {secondaryButton.label}
                  </Button>
                </Link>
              )}
              {thirdButton && (
                <Link href={thirdButton.url}>
                  <Button size="large" variant="outlined" fullWidth={isMedium ? true : false}>
                    {thirdButton.label}
                    <IcoShare width={'24px'} height={'24px'} />
                  </Button>
                </Link>
              )}
              {forthButton && (
                <Link href={forthButton.url}>
                  <Button size="large" variant="contained" color="primary">
                    {forthButton.label}
                  </Button>
                </Link>
              )}
              {openNewTabButton && (
                <Link href={openNewTabButton.url} rel="noopener noreferrer" target="_blank">
                  <Button size="large" variant="outlined" color="primary">
                    {openNewTabButton.label}
                    <IcoShare width={'24px'} height={'24px'} />
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
        </SectionRight>
      </Section>
    </Container>
  );
};

export default Provision;
