import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SupportContainer = styled(Container)(({ theme }) => ({
  display: 'grid',
  // backgroundColor: theme.palette.global01[700],
  // borderTop: '1px solid ',
  // borderColor: theme.palette.global01[300],
  gridTemplateColumns: `repeat(2, 1fr)`,
  gap: theme.spacing(3),
  paddingBlock: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
  },
}));

export const SupportSectionLeft = styled(Box)(({}) => ({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  padding: 0,
}));

export const SupportSectionRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
}));

export const Title = styled(Typography)(() => ({
  variant: 'heading05',
  fontWeight: 'bold',
}));

export const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral01[300],
}));

Description.defaultProps = {
  variant: 'paragraph01',
};
