import { FunctionComponent, ReactElement } from 'react';

import Carousel from 'components/@common/Carousel/Carousel';
import DefaultSection from 'components/@common/DefaultSection';
import { InfoPage } from 'components/@common/InfoPages/InfoPage';
import OtherSaySection from 'components/@common/OtherSaySection/OtherSaySection';
import Provision from 'components/@common/ProvisionSection/ProvisionSection';
import SeacrchEventSection from 'components/@common/SeacrchEventSection/SeacrchEventSection';
import ServiceSection from 'components/@common/ServiceSection/ServiceSection';
import Support from 'components/@common/Support/Support';
import TeamSection from 'components/@common/TeamSection/TeamSection';
import TrustedOrganizations from 'components/@common/trustedOrganizations';
import VacanciesSection from 'components/@common/VacanciesSection/VacanciesSection';
import { ContactFormContainer } from 'components/@contact-form';
import Direct from 'components/@homepage/direct/Direct';
import GetStarted from 'components/@homepage/getStarted';
import Questions from 'components/@homepage/questions';
import TitleSection from 'components/@common/TitleSection';

export type Layout = {
  id: number;
  __component: string;
  title?: string;
  description?: string;
  titleMaxWidth?: string | null;
  isHeroSection?: boolean | null;
  inverted?: boolean | null;
  invertedMobile?: boolean | null;
  margin?: string | null;
  headerSize?: string | null;
  primaryButton?: {
    id: number;
    label: string;
    url: string;
  } | null;
  secondaryButton?: {
    id: number;
    label: string;
    url: string;
  } | null;
  thirdButton?: {
    id: number;
    label: string;
    url: string;
  } | null;
  forthButton?: {
    id: number;
    label: string;
    url: string;
  } | null;
}[];

type Props = {
  layout: Layout;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentMapping: { [key: string]: FunctionComponent<any> } = {
  'section.carousel': Carousel,
  'section.default-section': DefaultSection,
  'section.trusted-organizations': TrustedOrganizations,
  'section.service-section': ServiceSection,
  'section.direct': Direct,
  'section.get-started': GetStarted,
  'section.q-and-a': Questions,
  'section.support': Support,
  'section.team-section': TeamSection,
  'section.vacancies-section': VacanciesSection,
  'section.other-say-section': OtherSaySection,
  'section.provision-section': Provision,
  'section.content-page': InfoPage,
  'section.contact-form': ContactFormContainer,
  'section.search-event-section': SeacrchEventSection,
  'section.title-section': TitleSection,
};

const LayoutRenderer = ({ layout }: Props): ReactElement => {
  return (
    <>
      {layout.map((section, index) => {
        const SectionComponent = componentMapping[section.__component];
        if (!SectionComponent) return null;
        return <SectionComponent key={index} {...section} />;
      })}
    </>
  );
};

export default LayoutRenderer;
