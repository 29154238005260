import { Container, Typography } from '@mui/material';
import * as React from 'react';

import { InfoContent } from './InfoPages.style';

export interface InfoPageProps {
  content?: string;
  margin?: 'top' | 'bottom' | 'both' | 'none';
}

export const InfoPage = ({ content, margin = 'both' }: InfoPageProps) => {
  return (
    <Container>
      <InfoContent $margin={margin}>
        <Typography variant="paragraph01">
          {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
        </Typography>
      </InfoContent>
    </Container>
  );
};
