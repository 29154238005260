import { Box, Container, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import config from 'config/env';

import { languageCodeMap } from 'utils/localConvert';

import { useLocale } from 'hooks/useLocale';
import useMedia from 'hooks/useMedia';
import { JobsService } from 'services/api';
import { ImageStrapi, JobStrapi } from 'services/api/types';

import { IcoArrowRight } from 'components/@icons';

import CustomImage from '../CustomImage';
import {
  Description,
  MoreDetailButton,
  Section,
  SectionLeft,
  SectionRight,
  SectionTitle,
  Title,
  VacanciesSectionCard,
  VacanciesSectionCards,
} from '.';

interface Props {
  icon?: React.ReactElement;
  tagline?: I18nKey;
  title: string;
  description?: string;
  checks?: I18nKey[];
  primaryCta?: { label: I18nKey; url: React.ComponentProps<typeof Link>['href'] };

  secondaryCta?: { label: I18nKey; url: React.ComponentProps<typeof Link>['href'] };
  image: ImageStrapi;
  margin?: 'top' | 'bottom' | 'both' | 'none';
  headerSize?: 'small' | 'large';
  titleMaxWidth?: string;
  isHeroSection?: boolean;
  inverted?: boolean;
  invertedMobile?: boolean;
}

const VacanciesSection = ({
  icon,
  tagline,
  title,
  description,
  image,
  margin = 'both',
  headerSize = 'small',
  titleMaxWidth = 'none',
  isHeroSection = false,
  inverted = false,
  invertedMobile = false,
}: Props) => {
  const { isSmall, isMedium } = useMedia();
  const { locale } = useLocale();
  const [jobs, setJobs] = useState<JobStrapi[] | []>([]);

  useEffect(() => {
    if (locale) {
      loadData(languageCodeMap[locale]);
    }
  }, [locale]);

  const loadData = async (locale: string) => {
    try {
      const response = await JobsService.getAllJobs(`locale=${locale}`);
      if (response && response?.data) {
        setJobs(response?.data);
      } else {
        setJobs([]);
      }
    } catch (error) {
      Sentry.captureException(error);
      // Even if the API failed, we still go to the next step, but hide description in the Organisation step
    } finally {
    }
  };
  return (
    <Container>
      <Section component="section" $margin={margin}>
        <SectionLeft
          {...(!isMedium
            ? {
                order: inverted ? 1 : 0,
              }
            : {
                order: invertedMobile ? 1 : 0,
              })}
          $fullwidth={false}
        >
          <>
            {tagline && (
              <Stack
                direction="row"
                gap={1}
                sx={(theme) => ({
                  color: theme.palette.neutral01[400],
                  fill: theme.palette.neutral01[400],
                })}
              >
                {icon && React.cloneElement(icon, { style: { width: '32px', height: '32px' } })}
                <Typography variant="heading04">
                  <FormattedMessage id={tagline} />
                </Typography>
              </Stack>
            )}
            <SectionTitle
              gutterBottom
              variant={tagline || headerSize === 'large' ? 'heading01' : 'heading02'}
              {...(isHeroSection && { component: 'h1' })}
              maxWidth={titleMaxWidth}
            >
              {/* <FormattedMessage id={title} /> */}
              {title}
            </SectionTitle>
            {description && (
              <Description variant="paragraph01">
                {/* <FormattedMessage id={description} /> */}
                {description}
              </Description>
            )}
            {jobs && (
              <VacanciesSectionCards $columns={1}>
                {jobs &&
                  Array.isArray(jobs) &&
                  jobs.map((job, i) => (
                    <VacanciesSectionCard key={i}>
                      <Title>
                        <Typography fontWeight={700} variant="heading04">
                          {job.attributes.title}
                        </Typography>
                      </Title>
                      <Description variant="paragraph01">{job.attributes.description}</Description>
                      <Link
                        href={`/jobs/${job.attributes.url}`}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <MoreDetailButton>
                          <Typography fontWeight={700} variant="paragraph02">
                            {job.attributes.labelButton}
                          </Typography>
                          <IcoArrowRight
                            style={{
                              width: '9.5px',
                              height: '9px',
                              marginLeft: '7px',
                            }}
                          />
                        </MoreDetailButton>
                      </Link>{' '}
                    </VacanciesSectionCard>
                  ))}
              </VacanciesSectionCards>
            )}
          </>
        </SectionLeft>
        {image && (
          <SectionRight
            {...(!isMedium
              ? {
                  order: inverted ? 0 : 1,
                }
              : {
                  order: invertedMobile ? 0 : 1,
                })}
            sx={{
              justifyContent: !inverted ? 'flex-end' : 'flex-start',
              ...(isMedium && { justifyContent: 'center' }),
              ...(isSmall && { justifyContent: 'center' }),
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                maxWidth: isHeroSection ? '564px' : '465px',
              }}
            >
              <CustomImage
                src={`${config.API_BASE}${image.img.data[0].attributes.url}?updatedAt=${image.img.data[0].attributes.updatedAt}`}
                alt={image.imgAlt || ''}
                width={image.img.data[0].attributes.width}
                height={image.img.data[0].attributes.height}
              />
            </Box>
          </SectionRight>
        )}
      </Section>
    </Container>
  );
};

export default VacanciesSection;
