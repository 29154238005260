import { Box, Button, Container, Pagination, Typography } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changePage, eventStateType, getEventsByCriteria } from 'redux/slices/eventsSlice';
import { AppDispatch, RootState } from 'redux/store';

import ConvertDatetoImage from 'utils/convertDatetoImage';
import { languageCodeMap } from 'utils/localConvert';

import { useLocale } from 'hooks/useLocale';
import useMedia from 'hooks/useMedia';
import useSearchDebounce from 'hooks/useSearchDebounce';
import { EventStrapi } from 'services/api/types';
import { theme } from 'theme';

import { IcoArrowRight } from 'components/@icons';

import { TextField } from '../TextField';
import HighlightedText from './HighlightedText';
import {
  EventSectionCard,
  EventSectionCardRight,
  EventSectionCards,
  MoreDetailButton,
  SearchInputSection,
} from './SeacrchEventSection.style';

interface Props {
  title?: string;
  labelButton?: string;
}

const SeacrchEventSection = ({ title, labelButton }: Props) => {
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const { locale } = useLocale();
  const { isMedium } = useMedia();
  const events = useSelector<RootState, EventStrapi[]>((state) => state.eventStore.events);
  const eventsCriteria = useSelector<RootState, eventStateType>((state) => state.eventStore);
  const [searchTerm, setSearchTerm] = useState('');
  const searchDebounce: string[] = useSearchDebounce(searchTerm, 1000);

  useEffect(() => {
    if (locale && eventsCriteria.meta.pagination.page) {
      loadData(languageCodeMap[locale]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, eventsCriteria.meta.pagination.page, eventsCriteria.searchText, searchDebounce]);

  const loadData = async (locale: string) => {
    let getEventQuery = {
      locale: locale,
      pagination: {
        page: eventsCriteria.meta.pagination.page,
        pageSize: 6,
      },
      sort: {
        date: 'ASC',
      },
      filters: {
        $or: [
          {
            description: {
              $contains: searchDebounce,
            },
          },
          {
            title: {
              $contains: searchDebounce,
            },
          },
        ],
      },
    };
    dispatch(
      getEventsByCriteria(
        QueryString.stringify(getEventQuery, {
          encodeValuesOnly: true,
        }),
      ),
    );
  };

  const handleSearchChange = (search: string) => {
    setSearchTerm(search);
  };

  const handChangePage = (_: ChangeEvent<unknown>, page: number) => {
    dispatch(changePage(page));
  };

  return (
    <Container
      sx={{
        marginY: theme.spacing(15),
      }}
    >
      <Box sx={{ width: { sm: '100%', md: '50%', lg: '60%' }, marginBottom: theme.spacing(1) }}>
        <Typography variant="heading04" component="h3">
          {title}
        </Typography>
        <SearchInputSection>
          <TextField value={searchTerm} handleChange={handleSearchChange} label="" />
          <Button fullWidth={isMedium ? true : false} variant="contained" color="primary">
            {labelButton}
          </Button>
        </SearchInputSection>
      </Box>

      {eventsCriteria && (
        <Typography
          variant="paragraph02"
          component="h3"
          sx={{ color: theme.palette.neutral01[300] }}
        >
          {`${intl.formatMessage({ id: 'common.searchEvent.found' })} ${
            eventsCriteria.meta.pagination.total
          } ${intl.formatMessage({ id: 'common.searchEvent.results' })}`}
        </Typography>
      )}
      <Box sx={{ marginY: theme.spacing(3) }}>
        {' '}
        <EventSectionCards $columns={2}>
          {events &&
            events.map((event, i) => (
              <EventSectionCard
                key={i}
                onClick={() => router.push(`/events/${event.attributes.url}`)}
              >
                {event.attributes?.date && <ConvertDatetoImage date={event.attributes?.date} />}
                <EventSectionCardRight>
                  <Box sx={{ minHeight: '170px' }}>
                    <HighlightedText
                      originalText={event.attributes.title}
                      type="title"
                      searchTerm={searchDebounce}
                    />
                    <HighlightedText
                      originalText={event.attributes.description}
                      type="desc"
                      searchTerm={searchDebounce}
                    />
                  </Box>
                  <Link href={`/events/${event.attributes.url}`}>
                    <MoreDetailButton>
                      <Typography fontWeight={700} variant="paragraph02">
                        {event.attributes.labelButton}
                      </Typography>
                      <IcoArrowRight width={'9.5px'} height={'9px'} style={{ marginLeft: '7px' }} />
                    </MoreDetailButton>
                  </Link>
                </EventSectionCardRight>
              </EventSectionCard>
            ))}
        </EventSectionCards>
        <Box sx={{ marginTop: theme.spacing(3), display: 'grid', justifyContent: 'center' }}>
          <Pagination
            count={eventsCriteria.meta.pagination.pageCount}
            variant="outlined"
            shape="rounded"
            page={eventsCriteria.meta.pagination.page}
            onChange={(_, page: number) => handChangePage(_, page)}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default SeacrchEventSection;
