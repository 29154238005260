import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
  $columns: number;
}

export const SearchInputSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: `5fr 2fr`,
  flexDirection: 'row',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'grid',
    flexDirection: 'column',
    gridTemplateColumns: `5fr `,
    width: '100%',
  },
}));

export const EventSectionCards = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$columns',
})<Props>(({ theme, $columns }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: `repeat(${$columns}, 1fr)`,
  gap: theme.spacing(2),
  marginTop: theme.spacing(6),

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    marginTop: theme.spacing(2),
  },
}));
export const EventSectionCard = styled(Box)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  borderRadius: theme.spacing(2),
  gridTemplateColumns: `1fr 9fr`,
  gap: theme.spacing(3),
  border: `1px solid ${theme.palette.neutral01[600]}`,
  padding: theme.spacing(3, 2),

  [theme.breakpoints.down('md')]: {
    margin: '0 auto',
    width: '100%',
  },
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const EventSectionCardRight = styled(Box)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(1),
  // position: 'relative',
}));

export const Description = styled(Typography)(({ theme }) => ({
  maxWidth: '666px',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    maxWidth: '643px',
  },
}));

export const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const MoreDetailButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: theme.palette.primary.main,
  fill: theme.palette.primary.main,

  '&:hover': {
    color: theme.palette.global01[300],
    fill: theme.palette.global01[300],
    gap: theme.spacing(0.5),
  },
}));
