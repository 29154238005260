import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TrustedContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBlock: theme.spacing(8),
  paddingInline: theme.spacing(2),
  backgroundColor: theme.palette.global01[700],
  borderRadius: theme.spacing(4),
}));

export const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: 290,
  },
}));

export const TrustedLogos = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  gridTemplateColumns: 'repeat(6, 1fr)',
  display: 'grid',
  gridAutoRows: 'auto',
  gridAutoColumns: 'auto',
  alignItems: 'center',
  // justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));
