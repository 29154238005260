import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface SectionProps {
  $margin: 'top' | 'bottom' | 'both' | 'none';
}
interface Props {
  $columns: number;
}

export const Section = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$margin',
})<SectionProps>(({ theme, $margin }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'top',
  gap: theme.spacing(4),
  marginTop: ['both', 'top'].includes($margin) ? theme.spacing(15) : 0,
  marginBottom: ['both', 'bottom'].includes($margin) ? theme.spacing(15) : 0,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginTop: ['both', 'top'].includes($margin) ? theme.spacing(6) : 0,
    marginBottom: ['both', 'bottom'].includes($margin) ? theme.spacing(6) : 0,
    gap: theme.spacing(4),
    padding: 0,
  },

  [theme.breakpoints.down('lg')]: {
    padding: 0,
    gap: theme.spacing(4),
  },
}));

export const SectionLeft = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$fullwidth',
})<{ $fullwidth: boolean }>(({ theme, $fullwidth }) => ({
  width: $fullwidth ? '100%' : '40%',
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(15),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
}));

export const SectionRight = styled(Box)(({ theme }) => ({
  width: '60%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral01['700'],
  margin: 0,
}));

export const Description = styled(Typography)(({ theme }) => ({
  maxWidth: '446px',
  color: theme.palette.global01['600'],
  [theme.breakpoints.down('sm')]: {
    maxWidth: '643px',
  },
}));

export const ServiceSectionCards = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$columns',
})<Props>(({ theme, $columns }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: `repeat(${$columns}, 1fr)`,
  gap: theme.spacing(3),
  marginTop: theme.spacing(15),
  marginBottom: theme.spacing(15),

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
}));
export const ServiceSectionCard = styled(Box)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(2),
  // gridTemplateColumns: `2fr 7fr`,
  gridTemplateColumns: `2fr 7fr`,
  gap: theme.spacing(3),
  border: `1px solid ${theme.palette.neutral01[600]}`,
  padding: theme.spacing(2, 2),
  backgroundColor: theme.palette.background.paper,

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  '&:hover': {
    cursor: 'pointer',
  },
}));
export const ServiceSectionCardRight = styled(Box)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(1),
}));
export const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));
export const DescriptionServiceCard = styled(Typography)(({ theme }) => ({
  maxWidth: '466px',
  color: theme.palette.neutral01['300'],
  [theme.breakpoints.down('sm')]: {
    maxWidth: '466px',
  },
}));

export const MoreDetailButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: theme.palette.primary.main,
  fill: theme.palette.primary.main,
  marginTop: theme.spacing(2),

  '&:hover': {
    color: theme.palette.global01[300],
    fill: theme.palette.global01[300],
    gap: theme.spacing(0.5),
  },
}));
