import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface SectionProps {
  $margin: 'top' | 'bottom' | 'both' | 'none';
}
interface Props {
  $columns: number;
}

export const Section = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$margin',
})<SectionProps>(({ theme, $margin }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingBlock: theme.spacing(15),
  marginTop: ['both', 'top'].includes($margin) ? theme.spacing(15) : 0,
  marginBottom: ['both', 'bottom'].includes($margin) ? theme.spacing(15) : 0,

  [theme.breakpoints.down('lg')]: {
    paddingBlock: theme.spacing(15),
    gap: theme.spacing(4),
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginTop: ['both', 'top'].includes($margin) ? theme.spacing(6) : 0,
    marginBottom: ['both', 'bottom'].includes($margin) ? theme.spacing(6) : 0,
    gap: theme.spacing(4),
    paddingBlock: theme.spacing(15),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginTop: ['both', 'top'].includes($margin) ? theme.spacing(6) : 0,
    marginBottom: ['both', 'bottom'].includes($margin) ? theme.spacing(6) : 0,
    gap: theme.spacing(4),
    paddingBlock: theme.spacing(6),
  },
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    marginTop: ['both', 'top'].includes($margin) ? theme.spacing(6) : 0,
    marginBottom: ['both', 'bottom'].includes($margin) ? theme.spacing(6) : 0,
    gap: theme.spacing(4),
    paddingBlock: theme.spacing(6),
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral01['700'],
  margin: 0,
}));

export const Description = styled(Typography)(({ theme }) => ({
  maxWidth: '606px',
  textAlign: 'center',
  color: theme.palette.global01['600'],
  [theme.breakpoints.down('sm')]: {
    maxWidth: '343px',
  },
}));

export const TeamSectionCards = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$columns',
})<Props>(({ theme, $columns }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: `repeat(${$columns}, 1fr)`,
  marginTop: theme.spacing(2),
  padding: theme.spacing(0, 6),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: `repeat(${$columns}, 1fr)`,
    padding: theme.spacing(0),
  },
}));
export const TeamSectionCard = styled(Box)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(1),
  gridTemplateColumns: `2fr 7fr`,
  gap: theme.spacing(2),
  padding: theme.spacing(2, 2),
  alignItems: 'top',
  height: 'fit-content',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 1),
    gap: theme.spacing(0),
    gridTemplateColumns: `repeat(1fr)`,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 0.5),
    gap: theme.spacing(0),
    gridTemplateColumns: `repeat(1fr)`,
  },
}));
export const TeamSectionCardRight = styled(Box)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(0),
  borderRadius: theme.spacing(1),
}));
export const Title = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'top',
  flexWrap: 'wrap',
  width: '9rem',
  color: theme.palette.background.paper,
  [theme.breakpoints.down('xs')]: {
    maxWidth: '103px',
  },
}));
export const DescriptionServiceCard = styled(Typography)(({ theme }) => ({
  maxWidth: '466px',
  width: '9rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'top',
  flexWrap: 'wrap',
  color: theme.palette.neutral01['500'],
  [theme.breakpoints.down('sm')]: {
    maxWidth: '343px',
  },
}));
